import React from 'react'
import Layout from '../../components/layout'
import {
    Vis,
    HeaderBanner,
    Flex,
    Text,
    WhiteSection,
    FooterBanner
} from '../../components/common'
import moment from 'moment-timezone'
import { navTo } from '../../utils/navigation'
import Buttons from '../../components/Buttons'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import api from '../../utils/api'
import styled from 'styled-components'
import ErrorIcon from '../../images/Error_24.svg'
import CloseDarkBlue from '../../images/Close_DarkBlue_30.svg'

const clustersScopeMapping = {
    "bank:accounts.basic:read": 'ACCOUNT_BALANCE_AND_DETAILS',      //ACCOUNT_NAME_TYPE_AND_BALANCE subcluster
    "bank:accounts.detail:read": 'ACCOUNT_BALANCE_AND_DETAILS',
    "bank:transactions:read": 'TRANSACTION_DETAILS',
    "bank:regular_payments:read": 'DIRECT_DEBITS_AND_SCHEDULED_PAYMENTS',
    "common:customer.basic:read": 'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS',    //NAME_AND_OCCUPATION subcluster
    "common:customer.detail:read": 'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS',
    "bank:payees:read": 'SAVED_PAYEES',
    "profile": "PROFILE"
}

const clustersToNameMapping = {
    'ACCOUNT_BALANCE_AND_DETAILS': 'Account balance and details',
    'TRANSACTION_DETAILS': 'Transaction details',
    'DIRECT_DEBITS_AND_SCHEDULED_PAYMENTS': 'Direct debits and scheduled payments',
    'NAME_AND_OCCUPATION_AND_CONTACT_DETAILS': 'Name and occupation and contact details',
    'SAVED_PAYEES': 'Saved payees',
    'PROFILE': 'User info'
}

const PillSpan = styled.span`
    margin: 0px 11px;
    padding: 6px;
    background: #f8f9fa;
    borderRadius: 4px;
    color: #000000;
`

const RevokeCloseButton = styled(Flex)`
:hover{
  opacity: 0.6;
}
`


const Timeline = ({ when, timeline, isDuration }) => {

    return (
        <Flex flex='vcenter between' style={{ padding: '15px 0' }}>
            <Text.Karbon16 style={{ color: '#333333', fontWeight: 'bold' }}>{when}</Text.Karbon16>
            <Text.Karbon16 style={{ color: '#000000' }}>{isDuration ?
                <span>
                    {timeline} sec.
                    <PillSpan>{((timeline / 60 / 60 / 24) % 1 !== 0) ? parseFloat(timeline / 60 / 60 / 24).toFixed(2) : Math.round(timeline / 60 / 60 / 24)}d</PillSpan>
                    <PillSpan>{((timeline / 60 / 60 / 24 / 7) % 1 !== 0) ? parseFloat(timeline / 60 / 60 / 24 / 7).toFixed(3) : Math.round(timeline / 60 / 60 / 24 / 7)}wk</PillSpan>
                    <PillSpan>{((timeline / 60 / 60 / 24 / 30) % 1 !== 0) ? parseFloat(timeline / 60 / 60 / 24 / 30).toFixed(4) : Math.round(timeline / 60 / 60 / 24 / 30)}mth</PillSpan>
                    <PillSpan>{((timeline / 60 / 60 / 24 / 365) % 1 !== 0) ? parseFloat(timeline / 60 / 60 / 24 / 365).toFixed(5) : Math.round(timeline / 60 / 60 / 24 / 365)}yr</PillSpan>
                </span> :
                <span>{moment(timeline).format('DD/MM/YYYY hh:mm a')}<span style={{ marginLeft: '5px' }}>({moment.tz(moment.tz.guess()).format('z')})</span></span>
            }
            </Text.Karbon16>
        </Flex>
    )
}

const DataCluster = ({ scopes = [] }) => {
    let clusterPills = {}
    Object.keys(clustersToNameMapping).forEach(c => {
        clusterPills[c] = []
    })

    Array.prototype.forEach.call(scopes, s => {
        if (clustersScopeMapping[s]) {
            clusterPills[clustersScopeMapping[s]].push(s)
        }
    })

    return (
        <>
            {Object.keys(clusterPills).map((key, index) => (
                <>
                    {index !== 0 && <Flex flex='nogrow' style={{ margin: '0px 24px', border: '1px solid #DADCE0' }}></Flex>}
                    <Flex flex='w100 nogrow between vcenter' style={{ padding: '12px 24px' }}>
                        <Text.Karbon14 style={{ color: '#333333', fontWeight: '800', textAlign: 'left' }}>
                            {clustersToNameMapping[key]}
                        </Text.Karbon14>

                        {/* Pills */}
                        <Flex flex='hend'>
                            {/* Pills goes here */}
                            {clusterPills[key].map(p => (
                                <div>
                                    <Flex>
                                        <div style={{ padding: '6px', background: '#f8f9fa', borderRadius: '4px', fontSize: '12px', fontWeight: '600', color: '#000000' }}>
                                            <Text.Karbon14 style={{ fontFamily: 'monospace', fontSize: '11px', color: '#ab0000' }}>{p}</Text.Karbon14>
                                        </div>
                                    </Flex>
                                </div>
                            ))}
                            {clusterPills[key].length === 0 &&
                                <div>
                                    <Flex>
                                        <div style={{ padding: '6px', width: '26px', textAlign: 'center', background: '#f8f9fa', borderRadius: '4px', fontSize: '12px', fontWeight: '600', color: '#000000' }}>
                                            <Text.Karbon14 style={{ fontFamily: 'monospace', fontSize: '11px', color: '#ab0000' }}>-</Text.Karbon14>
                                        </div>
                                    </Flex>
                                </div>
                            }
                        </Flex>
                    </Flex>

                </>
            ))
            }

        </>
    )
}

const ActivePill = ({ lifecycle }) => {
    return (
        <Flex flex='vcenter' style={{ minWidth: '100px', padding: '5px 15px', border: '1px solid #DADCE0', borderRadius: '490px' }}>
            <span style={{ width: '10px', height: '10px', borderRadius: '50%', marginBottom: '2px', marginRight: '5px', background: (lifecycle === 'EXPIRED' || lifecycle === 'REVOKED') ? '#E84A5E' : '#34C240' }}></span>
            {lifecycle === 'EXPIRED' || lifecycle === 'REVOKED'
                ? <Text.Karbon16 style={{ fontWeight: 'bold', color: '#333333', paddingTop: '3px' }}>
                    Inactive
                </Text.Karbon16>
                : <Text.Karbon16 style={{ fontWeight: 'bold', color: '#333333', paddingTop: '3px' }}>
                    Active
                </Text.Karbon16>
            }
        </Flex>
    )
}

const ConsentDetails = (props) => {
    let { location: { state = {} } = {} } = props
    if (state === null) state = {}

    const { consentId, lastCollected, auth: { status, timeline = {} } = {}, dataholder: { logoUri, brandName } = {}, scopes = {} } = state

    const [openRevokeBanner, setOpenRevokeBanner] = React.useState(false)
    const [revokeMessage, setRevokeMesssage] = React.useState('The consent has now been revoked. You will see this is your inactive consents now.')

    let { isLoading: userIsLoading, data: userData } = useQuery('users', async () => {
        return new Promise((resolve, reject) => {
            api.getUserDetail().then((returnData) => resolve(returnData.data)).catch(err => reject(err))
        })
    }, {
        retry: 1,
        onError: (error) => {
            const { response } = error
            if (!response || response.status === 403) {
                // not authorized route back to intro page
                navTo.introduction_1()
            }
        },
        refetchOnWindowFocus: false
    })

    const collectDataMutation = useMutation(async (consentId) => {
        const { data } = await api.collectData(consentId)
        return data
    }, {
        onSuccess: (data) => {
            const { requestedScopes, internalDataHolderBrandId } = data
            // route to page 9
            navTo.collectingyourdata_9({ internalRequestedScopes: requestedScopes, internalDataHolderBrandId })
        },
        onError: (err) => {
            console.log("Collect data request failed", err)
            // update error message with
        }
    })

    const queryClient = useQueryClient()
    const revokeConsent = useMutation(async (consentId) => {
        const { data } = await api.revokeConsent(consentId)
        return data
    }, {
        onSuccess: (data) => {
            console.log("Revoke consent success", data)
            queryClient.invalidateQueries('consentsList')
            setOpenRevokeBanner(true)
            setRevokeMesssage('The consent has now been revoked. You will see this is your inactive consents now.')
        },
        onError: (err) => {
            console.log("Unable to revoke consent", err)
            setOpenRevokeBanner(true)
            setRevokeMesssage("Unable to revoke consent, please try again.")
        }
    })

    const expired = status && (status.lifecycle === 'EXPIRED' || status.lifecycle === 'REVOKED')

    return (
        <Layout.ColumnLayout title={'Consent details'}>
            <HeaderBanner hamburger={false} commercial={true} commercialProperties={userData} userIsLoading={userIsLoading} />
            <Vis.MobileOnly></Vis.MobileOnly>
            <Vis.TabletOnly></Vis.TabletOnly>
            <Vis.DesktopOnly flex="col">
                <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
                    <WhiteSection flex="col" style={{ padding: '0px' }}>
                        <Flex flex='nogrow vcenter' style={{ marginBottom: '2rem' }}>
                            <Text.H3>Consent Details</Text.H3>
                            <Buttons width='200px' padding='10px' onClick={() => navTo.dashboard()}>Back to dashboard</Buttons>
                        </Flex>

                        {/* Revoke message banner  */}
                        {openRevokeBanner && <Flex flex='vcenter w100 mb-2' style={{ background: '#F5FAFF', padding: '15px 20px', borderRadius: '4px', marginBottom: '16px' }}>
                            <Flex flex='vcenter'>
                                <img src={ErrorIcon} alt="" />
                                <Text.Karbon16 style={{ padding: '5px 0 0 21px', fontWeight: '500' }}>
                                    {revokeMessage}
                                </Text.Karbon16>
                            </Flex>
                            <RevokeCloseButton flex='vcenter hend' onClick={() => setOpenRevokeBanner(false)}>
                                <Text.Karbon16 style={{ padding: '5px 5px 0 0', fontWeight: '500' }}>Close</Text.Karbon16>
                                <button style={{ background: 'transparent' }}>
                                    <img src={CloseDarkBlue} alt="" />
                                </button>
                            </RevokeCloseButton>
                        </Flex>
                        }

                        <Flex flex='nogrow between' style={{ gap: '1rem', height: '100%', maxHeight: '420px' }}>
                            {/* Data holder */}
                            <Flex flex='nogrow between col ' style={{ width: '100%', maxWidth: '605px', gap: '1rem' }}>
                                <Flex flex='nogrow vstart pad-md between' style={{ minHeight: '166px', border: '1px solid #DADCE0', borderRadius: '4px' }}>
                                    <div>
                                        <img src={logoUri} width="35" height="35" alt="data holder logo" style={{ objectFit: 'contain' }} />
                                        <Text.Karbon16 style={{ fontWeight: 'bold', color: '#333333', marginLeft: '5px' }}>{brandName}</Text.Karbon16>
                                        <div style={{ marginLeft: '2.6rem' }}>
                                            <Text.Karbon16 style={{ color: '#333333' }}>Consent ID: # {consentId}</Text.Karbon16>
                                        </div>
                                        <div style={{ marginLeft: '2.6rem' }}>
                                            <Text.Karbon16 style={{ color: '#333333' }}>Last collected: {lastCollected ? moment(lastCollected).format('DD/MM/YYYY hh:mm a') : '-'}</Text.Karbon16>
                                        </div>
                                    </div>
                                    <div>
                                        {/* default to expired */}
                                        <ActivePill lifecycle={status ? status.lifecycle : 'EXPIRED'} />
                                    </div>
                                </Flex>
                                {/* Timeline */}
                                <Flex flex='col pad-md' style={{ height: '100%', border: '1px solid #DADCE0', borderRadius: '4px' }}>
                                    <Timeline when={`${expired ? 'Expired on:' : 'Expires on:'}`} timeline={timeline.revoked ? timeline.revoked : (timeline.sharingDuration > 0 ? timeline.sharingEnd : timeline.consented)} />
                                    {expired && <Timeline when="Scheduled expiry date:" timeline={timeline.sharingDuration > 0 ? timeline.sharingEnd : timeline.consented} />}
                                    <Timeline when="Granted on:" timeline={timeline.consented} />
                                    <Timeline when="Duration:" isDuration={true} timeline={timeline.sharingDuration} />
                                </Flex>
                            </Flex>

                            {/* Data cluster */}
                            <Flex flex='w100 h100 col' style={{ border: '1px solid #DADCE0', borderRadius: '4px' }}>
                                <Flex flex='nogrow vstart between' style={{ padding: '24px' }}>
                                    <Text.Karbon16 style={{ fontWeight: '800' }}>Data cluster</Text.Karbon16>
                                    <Text.Karbon16 style={{ fontWeight: '800' }}>Auth Scope ID</Text.Karbon16>
                                </Flex>
                                <DataCluster scopes={scopes} />
                            </Flex>

                        </Flex>
                        {
                            status && status.lifecycle === 'CURRENT' && <Flex flex='nogrow hend' style={{ marginTop: '40px', gap: '15px' }}>
                                {/* Show when active={true} */}
                                <Buttons width='174px' padding='8px 3rem' onClick={() => revokeConsent.mutate(consentId)}>{
                                    revokeConsent.isLoading ?
                                        <span className="bootstrap">
                                            <div className="spinner-border spinner-border-sm text-dark" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </span> :
                                        'Revoke'}
                                </Buttons>
                                <Buttons width='174px' padding='8px 3rem' onClick={() => collectDataMutation.mutate(consentId)} >{collectDataMutation.isLoading ?
                                    <span className="bootstrap">
                                        <div className="spinner-border spinner-border-sm text-dark" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </span> :
                                    'Collect data'}
                                </Buttons>
                            </Flex>
                        }

                    </WhiteSection>
                </WhiteSection>
            </Vis.DesktopOnly>
            <WhiteSection flex="col nogrow">
                <FooterBanner />
            </WhiteSection>
        </Layout.ColumnLayout >
    )
}

export default ConsentDetails
