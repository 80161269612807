import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    min-width: ${props => props.width || '100%'};
    background: #fff;
    font-size: 16px;
    font-weight: 800;
    color: #000000;
    border: 1px solid #333333;
    border-radius: 9px;
    padding: ${props => props.padding || '0'};
    
    &:hover, &:focus {
    color: rgba(51, 51, 51, 0.6);
    border: 1px solid rgba(51, 51, 51, 0.5);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.12);
  }
`

const Buttons = ({ children, ...props }) => {
    return (
        <Button {...props}>
            {children}
        </Button>
    )
}

export default Buttons
